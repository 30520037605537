<template>
    <v-main>
        <div
            class="viz-main"
            :class="classDesign"
        >
          <section
            class="ava_viz_bg"
          >
            <img v-if="classDesign === 'default_viz'" class="def-img-left" src="/img/vizitka/default/default-bg-top-left.png" />
            <img v-if="classDesign === 'default_viz'" class="def-img-right" src="/img/vizitka/default/default-bg-top-right.png" />
            <img v-if="classDesign === 'dark_viz'" class="dark-img-left" src="/img/vizitka/dark/dark-bg-top-left.png" />
            <img v-if="classDesign === 'dark_viz'" class="dark-img-right" src="/img/vizitka/dark/dark-bg-top-right.png" />
            <img v-if="classDesign === 'dark2_viz'" class="dark2-img-left" src="/img/vizitka/dark2/dark2-bg-top-left.png" />
            <img v-if="classDesign === 'dark2_viz'" class="dark2-img-right" src="/img/vizitka/dark2/dark2-bg-top-right.png" />
            <img v-if="classDesign === 'white_viz'" class="white-img-left" src="/img/vizitka/white/white-bg-top-left.png" />
            <img v-if="classDesign === 'white_viz'" class="white-img-right" src="/img/vizitka/white/white-bg-top-right.png" />
            <img v-if="classDesign === 'white2_viz'" class="white2-img-left" src="/img/vizitka/white2/white2-bg-top-left.png" />
            <img v-if="classDesign === 'white2_viz'" class="white2-img-right" src="/img/vizitka/white2/white2-bg-top-right.png" />
            <img v-if="classDesign === 'pink_viz'" class="pink-img-left" src="/img/vizitka/pink/pink-bg-top-left.png" />
            <img v-if="classDesign === 'pink_viz'" class="pink-img-right" src="/img/vizitka/pink/pink-bg-top-right.png" />
            <img v-if="classDesign === 'brown_viz'" class="brown-img-left" src="/img/vizitka/brown/brown-bg-top-left.png" />
            <img v-if="classDesign === 'brown_viz'" class="brown-img-right" src="/img/vizitka/brown/brown-bg-top-right.png" />
              <div class="d-flex grow flex-wrap">
                  <div
                      class="v-avatar mx-auto elevation-6 grey mt-16"
                      style="height: 128px; min-width: 128px; width: 128px"
                  >
                      <div class="v-image v-responsive theme--light">
                          <img v-if="userData.avatar"
                                :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                alt=""
                          >
                          <div class="v-responsive__content" style="width: 250px"></div>
                      </div>
                  </div>

                  <v-card-text class="text-center mt-7">
                      <h2 class="viz-h2">
                          {{ userData.name }} {{ userData.last_name }}
                      </h2>
                  </v-card-text>
              </div>
          </section>

          <div class="content-section">
            <section
                v-if="userData.about_me_viz"
                id="viz-page"
            >
                <div class="mb-5">
                    <span v-html="userData.about_me_viz"></span>
                </div>
            </section>

            <section
                v-if="userData.photo_money"
                id="viz-page"
            >
                <div class="mt-5 mb-5">
                    <h3 class="viz-h3 mb-5 text-center">
                        Доход от LR
                    </h3>
                    <p
                        class="viz-img"
                        style="width: 100%; height: auto"
                    >
                        <img
                            style="width: 100%;"
                            :src="`${ $store.state.serverPath }/storage/${ userData.photo_money }`"
                            alt=""
                        />
                    </p>
                </div>
            </section>

            <section
                v-if="userData.photo_auto"
                id="viz-page"
            >
                <div class="mt-5 mb-5">
                    <h3 class="viz-h3 mb-5 text-center">
                        Авто от LR
                    </h3>
                    <p
                        class="viz-img"
                        style="width: 100%; height: auto"
                    >
                        <img
                            style="width: 100%;"
                            :src="`${ $store.state.serverPath }/storage/${ userData.photo_auto }`"
                            alt=""
                        />
                    </p>
                </div>
            </section>

            <section
                id="viz-page"
            >
                <div class="mt-5 mb-5">
                    <h3 v-if="userData.viz_title_one" class="viz-h3 mb-5 text-center">
                        {{ userData.viz_title_one }}
                    </h3>
                    <p
                        class="viz-img"
                        style="width: 100%; height: auto"
                        v-if="userData.viz_img_one"
                    >
                        <img
                            style="width: 100%;"
                            :src="`${ $store.state.serverPath }/storage/${ userData.viz_img_one }`"
                            alt=""
                        />
                    </p>
                    <h3 v-if="userData.viz_title_two" class="viz-h3 mt-15 mb-5 text-center">
                        {{ userData.viz_title_two }}
                    </h3>
                    <p
                        class="viz-img mt-5"
                        style="width: 100%; height: auto"
                        v-if="userData.viz_img_two"
                    >
                        <img
                            style="width: 100%;"
                            :src="`${ $store.state.serverPath }/storage/${ userData.viz_img_two }`"
                            alt=""
                        />
                    </p>
                    <h3 v-if="userData.viz_title_three" class="viz-h3 mt-15 mb-5 text-center">
                        {{ userData.viz_title_three }}
                    </h3>
                    <p
                        class="viz-img mt-5"
                        style="width: 100%; height: auto"
                        v-if="userData.viz_img_three"
                    >
                        <img
                            style="width: 100%;"
                            :src="`${ $store.state.serverPath }/storage/${ userData.viz_img_three }`"
                            alt=""
                        />
                    </p>
                    <h3 v-if="userData.viz_title_four" class="viz-h3 mt-15 mb-5 text-center">
                        {{ userData.viz_title_four }}
                    </h3>
                    <p
                        class="viz-img mt-5"
                        style="width: 100%; height: auto"
                        v-if="userData.viz_img_four"
                    >
                        <img
                            style="width: 100%;"
                            :src="`${ $store.state.serverPath }/storage/${ userData.viz_img_four }`"
                            alt=""
                        />
                    </p>
                    <h3 v-if="userData.viz_title_five" class="viz-h3 mt-15 mb-5 text-center">
                        {{ userData.viz_title_five }}
                    </h3>
                    <p
                        class="viz-img mt-5"
                        style="width: 100%; height: auto"
                        v-if="userData.viz_img_five"
                    >
                        <img
                            style="width: 100%;"
                            :src="`${ $store.state.serverPath }/storage/${ userData.viz_img_five }`"
                            alt=""
                        />
                    </p>
                </div>
            </section>

            <section
                v-if="userData.dop_viz"
                id="viz-page"
            >
                <div class="mt-5 mb-5 dop_viz_div">
                    <span v-html="userData.dop_viz"></span>
                </div>
            </section>
          </div>

          <section
              class="pt-16 pb-16 viz-bottom-section"
          >
            <!-- <img v-if="classDesign === 'default_viz'" class="default-img-bottom-left" src="/img/vizitka/default/default-bg-bottom-left.png" /> -->
            <!-- <img v-if="classDesign === 'dark_viz'" class="dark-img-bottom-left" src="/img/vizitka/dark/dark-bg-bottom-left.png" /> -->
            <img v-if="classDesign === 'dark2_viz'" class="dark2-img-bottom-left" src="/img/vizitka/dark2/dark2-bg-bottom-left.png" />
            <img v-if="classDesign === 'white_viz'" class="white-img-bottom-left" src="/img/vizitka/white/white-bg-bottom-left.png" />
            <img v-if="classDesign === 'white_viz'" class="white-img-bottom-right" src="/img/vizitka/white/white-bg-bottom-right.png" />
            <img v-if="classDesign === 'white2_viz'" class="white2-img-bottom-right" src="/img/vizitka/white2/white2-bg-bottom-right.jpg" />
            <img v-if="classDesign === 'pink_viz'" class="pink-img-bottom-right" src="/img/vizitka/pink/pink-bg-bottom-right.png" />
            <!-- <img v-if="classDesign === 'brown_viz'" class="brown-img-bottom-left" src="/img/vizitka/brown/brown-bg-bottom-left.png" /> -->
              <v-row>
                  <v-col class="text-center">
                      <div class="input-group mb-3">
                          <v-tooltip
                              top
                              v-if="userData.phone_whatsapp"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <a
                                      style="text-decoration: none"
                                      :href="`https://wa.me/${userData.phone_whatsapp}`"
                                      target="_blank"
                                  >
                                      <v-btn
                                          fab
                                          dark
                                          small
                                          class="mr-4"
                                          color="success"
                                          v-bind="attrs"
                                          v-on="on"
                                      >
                                          <v-icon>mdi-whatsapp</v-icon>
                                      </v-btn>
                                  </a>
                              </template>
                              <span>Связаться в WhatsApp</span>
                          </v-tooltip>

                          <v-tooltip
                              top
                              v-if="userData.telegram"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <a
                                      style="text-decoration: none"
                                      :href="`${userData.telegram}`"
                                      target="_blank"
                                  >
                                      <v-btn
                                          fab
                                          dark
                                          small
                                          class="mr-4"
                                          color="blue"
                                          v-bind="attrs"
                                          v-on="on"
                                      >
                                          <v-icon>fab fa-telegram-plane</v-icon>
                                      </v-btn>
                                  </a>
                              </template>
                              <span>Связаться в Telegram</span>
                          </v-tooltip>

                          <v-tooltip
                              top
                              v-if="userData.phone_viber"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <a
                                      :href="`viber://chat?number=${userData.phone_viber}`"
                                      target="_blank"
                                      style="text-decoration: none"
                                  >
                                      <v-btn
                                          fab
                                          dark
                                          small
                                          class="mr-4"
                                          color="deep-purple lighten-2"
                                          v-bind="attrs"
                                          v-on="on"
                                      >
                                          <v-icon>fab fa-viber</v-icon>
                                      </v-btn>
                                  </a>
                              </template>
                              <span>Связаться в Viber</span>
                          </v-tooltip>
                      </div>
                  </v-col>
              </v-row>
          </section>
        </div>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Vizitka",
    data: () => ({
        loading: true,
        lr_number: '',
        inst: '',
    }),
    metaInfo: {
        title: 'Визитка партнера LR Health & Beauty',
        meta: [
            { vmid: 'description', property: 'description', content: 'Командный сайт newlvl. Инструменты для сетевого бизнеса' },
            { vmid: 'og:title', property: 'og:title', content: 'Визитка партнера LR Health & Beauty' },
            { vmid: 'og:description', property: 'og:description', content: 'Командный сайт newlvl. Инструменты для сетевого бизнеса' },
            { vmid: 'og:image', property: 'og:image', content: '/img/newlvl_logo.png' },
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push ({name: 'Vizitka', query: { partner: partnerNum }})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }
        this.lr_number = this.$route.query.partner
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUserV'
        }),
        dataUser() {
            return this.userData
        },
        classDesign() {
            let designClass
            if (this.userData.viz_design === 'default') {
                designClass = 'default_viz'
            }
            if (this.userData.viz_design === 'dark') {
                designClass = 'dark_viz'
            }
            if (this.userData.viz_design === 'brown') {
                designClass = 'brown_viz'
            }
            if (this.userData.viz_design === 'dark2') {
                designClass = 'dark2_viz'
            }
            if (this.userData.viz_design === 'white') {
                designClass = 'white_viz'
            }
            if (this.userData.viz_design === 'white2') {
                designClass = 'white2_viz'
            }
            if (this.userData.viz_design === 'pink') {
                designClass = 'pink_viz'
            }

            return designClass
        }
    },
    methods: {
        ...mapActions({
            showUser: 'user/showUserPageV'
        }),
    },
    mounted() {
        this.showUser(this.lr_number)
        this.loading = false
    }
}
</script>

<style lang="sass">
.viz-main
    font-size: 16px
    line-height: 20px
    overflow: hidden

#viz-page
    max-width: 1000px
    margin: 0 auto
    padding-bottom: 30px

.viz-main.default_viz
    background: #fff
    color: #0c0c0d

.viz-main.dark_viz
    background: #141515
    color: #fff

.viz-main.dark2_viz
    color: #fff

.viz-main.brown_viz
    background: #3f2918
    color: #fff

.viz-main.default_viz .ava_viz_bg
    height: 320px
    background: -webkit-linear-gradient(180deg, #8ba27396, rgba(255, 255, 255, 0)) /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
    background: -moz-linear-gradient(180deg, #8ba27396, rgba(255, 255, 255, 0)) /* Firefox 3.6-15 */
    background: -o-linear-gradient(180deg, #8ba27396, rgba(255, 255, 255, 0)) /* Opera 11.1-12 */
    background: linear-gradient(180deg, #8ba27396, rgba(255, 255, 255, 0))

.def-img-right
  max-height: 320px
  position: absolute
  right: 15%
  opacity: 0.4

.def-img-left
  max-height: 320px
  position: absolute
  left: 10%
  opacity: 0.4

.viz-main.dark_viz .ava_viz_bg
    height: 320px
    background: -webkit-linear-gradient(180deg, rgba(20, 21, 21, 0.65), rgb(20, 21, 21)) /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
    background: -moz-linear-gradient(180deg, rgba(20, 21, 21, 0.65), rgb(20, 21, 21)) /* Firefox 3.6-15 */
    background: -o-linear-gradient(180deg, rgba(20, 21, 21, 0.65), rgb(20, 21, 21)) /* Opera 11.1-12 */
    background: linear-gradient(180deg, rgba(20, 21, 21, 0.65), rgb(20, 21, 21))
    background-size: auto

.dark-img-right
    max-height: 320px
    position: absolute
    right: 0
  
.dark-img-left
    max-height: 320px
    position: absolute
    left: 0

.viz-main.brown_viz .ava_viz_bg
    height: 320px
    background: -webkit-linear-gradient(180deg, rgba(63, 41, 24, 0.63), rgb(63, 41, 24)) /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
    background: -moz-linear-gradient(180deg, rgba(63, 41, 24, 0.63), rgb(63, 41, 24)) /* Firefox 3.6-15 */
    background: -o-linear-gradient(180deg, rgba(63, 41, 24, 0.63), rgb(63, 41, 24)) /* Opera 11.1-12 */
    background: linear-gradient(180deg, rgba(63, 41, 24, 0.63), rgb(63, 41, 24))
    background-size: auto

.brown-img-right
    max-height: 320px
    position: absolute
    right: 0
  
.brown-img-left
    max-height: 320px
    position: absolute
    left: 0

.viz-main.white_viz .ava_viz_bg
    background-color: #fff

.white-img-right
  position: absolute
  right: 0

.white-img-left
  max-height: 320px
  position: absolute
  left: 0

.viz-main.white_viz .viz-bottom-section
    background-color: #fff

.white-img-bottom-left
  max-height: 200px
  position: absolute
  left: 0
  bottom: 0

.white-img-bottom-right
  max-height: 200px
  position: absolute
  right: 0
  bottom: 0

.content-section
  padding: 40px 20px 0 

.viz-main.white_viz .content-section
    background: url(/img/vizitka/white/white-bg-2.jpg)
    background-size: contain
    background-repeat: repeat-y

.viz-main.white2_viz .ava_viz_bg
  background-color: #f8f8f8

.white2-img-right
  position: absolute
  right: 0

.white2-img-left
  max-height: 320px
  position: absolute
  left: 0

.viz-main.white2_viz .content-section
  background-color: #f8f8f8

.viz-main.white2_viz .viz-bottom-section
  background-color: #edecf1

.white2-img-bottom-right
  max-height: 200px
  position: absolute
  right: 0
  bottom: 0

.viz-main.pink_viz .ava_viz_bg
    background-color: #f6d0cd

.viz-main.pink_viz .content-section
    background: url(/img/vizitka/pink/pink-bg-2.png)
    background-size: contain
    background-repeat: repeat-y

.viz-main.pink_viz .viz-bottom-section
    background-color: #f6d0cd

.pink-img-right
    position: absolute
    right: 0
  
.pink-img-left
    max-height: 320px
    position: absolute
    left: 0
    
.pink-img-bottom-right
    max-height: 200px
    position: absolute
    right: 0
    bottom: 0

.viz-main.dark2_viz .ava_viz_bg
    background-color: #141414

.dark2-img-right
  position: absolute
  right: 0

.dark2-img-left
  max-height: 320px
  position: absolute
  left: 10%
  z-index: 2

.viz-main.dark2_viz .content-section
    background: url(/img/vizitka/dark2/dark2-bg-2.png)
    background-size: contain
    background-repeat: repeat-y

.viz-main.dark2_viz .viz-bottom-section
    background-color: #141414

.dark2-img-bottom-left
  max-height: 200px
  position: absolute
  left: 10%
  bottom: 0

.viz-img img
    border-radius: 10px

.viz-main.default_viz .viz-img img
    box-shadow: 0 2px 28px rgba(111, 111, 111, 0.53), 0 10px 10px rgba(4, 4, 4, 0.32)

.viz-main.dark_viz .viz-img img
    box-shadow: 0 2px 28px rgba(187, 186, 186, 0.53), 0 10px 10px rgba(0, 0, 0, 0.32)

.viz-main.brown_viz .viz-img img
    box-shadow: 0 2px 28px rgba(217, 183, 0, 0.53), 0 10px 10px rgba(68, 24, 7, 0.84)

.viz-h2
    font-size: 18px
    text-transform: uppercase
    font-weight: 500
    z-index: 10
    position: relative

.viz-main.default_viz .viz-h2
    color: #111

.viz-main.dark_viz .viz-h2
    color: #fff

.viz-main.brown_viz .viz-h2
    color: #fbdb68

.viz-main.dark2_viz .viz-h2
    color: #e8c405

.viz-h3
    font-size: 18px
    text-transform: uppercase
    font-weight: 500

.viz-main.default_viz .viz-h3
    color: #659d83

.viz-main.dark_viz .viz-h3
    color: #fff

.viz-main.brown_viz .viz-h3
    color: #fbdb68
    
.viz-main.dark2_viz .viz-h3
    color: #e8c405

.dop_viz_div
    text-align: center

.dop_viz_div a
    text-decoration: none
    border-radius: 20px
    padding: 10px 20px

.viz-main.default_viz .dop_viz_div a
    color: #659d83
    border: 1px solid #659d83

.viz-main.dark_viz .dop_viz_div a
    color: #fff
    border: 1px solid #fff

.viz-main.brown_viz .dop_viz_div a
    color: #fbdb68
    border: 1px solid #fbdb68

.viz-main.white_viz .dop_viz_div a
    color: #00abff
    border: 1px solid #00abff

.viz-main.white2_viz .dop_viz_div a
    color: #3e1f13
    border: 1px solid #3e1f13

.viz-main.pink_viz .dop_viz_div a
    color: #3e1f13
    border: 1px solid #3e1f13

.viz-main.dark2_viz .dop_viz_div a
    color: #e8c405
    border: 1px solid #e8c405

.viz-main.default_viz .viz-bottom-section
    background-color: #3e802773

.viz-main.dark_viz .viz-bottom-section
    background-color: #1e1e1e

.viz-main.brown_viz .viz-bottom-section
    background-color: #5e4430
    
.ql-align-center 
    text-align: center

@media(max-width: 1000px)
  .def-img-right
    right: 5%

  .def-img-left
    left: 0%
    
  .dark2-img-bottom-left
    left: 0

  .dark2-img-left
    left: 0

  .brown-img-right
    opacity: 0.5

@media(max-width: 800px)
  .brown-img-left
    display: none
</style>